import React from "react";

const Main: React.FC = () => {
    return (
        <div className="flex items-center justify-center h-screen flex-col">
            <h1 className="text-7xl text-center">Website is undergoing maintenance</h1>
        </div>
    );
};

export default Main;