import React from 'react';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
    return (
        <div className="flex items-center justify-center h-screen flex-col">
            <h1 className="text-9xl text-center pb-10">404 Not Found</h1>
            <Link to="/" className="text-3xl text-blue-500">Back to home</Link>
        </div>
    );
};

export default NotFound;
