import React, { Suspense, lazy } from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import NotFound from './pages/NotFound';
import Main from './pages/Main';

const Wordle = lazy(() => import('./pages/Wordle/Wordle'));

const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<Main />} />
                <Route
                path="/wordle"
                element={
                    <Suspense fallback={<div>Loading...</div>}>
                    <Wordle />
                    </Suspense>
                }
                />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    );
};

export default App;
